import { IconName, IconStyleProps } from "@nju/icons";

export enum HoverMenuElement {
  account = "account",
  offer = "offer",
  help = "help",
  null = "null",
}

type IHeaderMenuItemLinkBadgeProps = {
  title: string;
} & IconStyleProps;

export type IHeaderMenuItemLinkProps = {
  href: string;
  title: string;
  badge?: IHeaderMenuItemLinkBadgeProps;
  icon?: IconName;
  additionalIcon?: IconName;
};

export type IHeaderMenuItemProps = {
  icon: IconName;
  title: string;
  links: IHeaderMenuItemLinkProps[];
};

type IHeaderMenuLinksItemProps = {
  title: string;
  href: string;
};

export type IHeaderMenuLinksProps = {
  title?: string;
  link?: IHeaderMenuLinksItemProps;
  alert?: string;
  loggedIn: boolean;
};

export type IHeaderMenuProps = {
  [key in HoverMenuElement]: {
    items: IHeaderMenuItemProps[];
    links: IHeaderMenuLinksProps[];
  };
};

export type INavigationMenuProps = {
  href?: string;
  title: string;
  menuElement: HoverMenuElement | null;
};
