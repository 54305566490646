import { createStitches, PropertyValue } from "@stitches/react";
export { cva } from "class-variance-authority";
export type { VariantProps as Variants } from "@stitches/react";

const themeDefinition = {
  colors: {
    white: "hsl(0, 0%, 100%)",
    black: "hsl(0, 0%, 0%)",
    black20: "hsl(0, 0%, 0%, 20%)",
    ripeLemon: "hsl(59, 87%, 49%)",
    cerulean: "hsl(188, 100%, 40%)",
    brightCerulean: "hsl(188, 78%, 47%)",
    blueLagoon: "hsl(192, 100%, 28%)",
    daintree: "hsl(203, 100%, 12%)",
    porcelain: "hsl(180, 17%, 93%)",
    geyser: "hsl(187, 19%, 83%)",
    tiaMaria: "hsl(356, 98%, 44%)",
    iron: "hsl(56, 12%, 82%)",
    beige: "hsl(0, 0%, 94%)",
  },
  space: {
    "0": "0px",
    "5": "5px",
    "10": "10px",
    "12": "12px",
    "15": "15px",
    "20": "20px",
    "25": "25px",
    "30": "30px",
    "35": "35px",
    "40": "40px",
    "50": "50px",
    "60": "60px",
    "70": "70px",
    "80": "80px",
    "90": "90px",
    auto: "auto",
  },
  zIndices: {
    under1: "-100",
    "1": "100",
    "2": "200",
    "3": "300",
    "4": "400",
    "5": "500",
    "6": "600",
    "7": "700",
    listbox: "800",
    tooltip: "1000",
  },
  fontSizes: {
    "10": "10px",
    "12": "12px",
    "14": "14px",
    "16": "16px",
    "20": "20px",
    "23": "23px",
    "24": "24px",
    "30": "30px",
    "32": "32px",
    "34": "34px",
    "40": "40px",
    "50": "50px",
    "63": "63px",
    "70": "70px",
    "110": "110px",
  },
  fonts: {
    crocante: "Crocante, TTFirs, Arial, sans-serif",
    ttfirs: "TTFirs, Arial, sans-serif",
  },
  borderWidths: {
    "1": "1px",
    "2": "2px",
  },
  shadows: {
    basic: "2px 2px 10px",
    popup: "0 20px 40px 0 $colors$black20",
    cerulean: "0 2px 10px 0 $colors$cerulean",
  },
  radii: {
    "0": "0px",
    "4": "4px",
    "8": "8px",
    "12": "12px",
    "20": "20px",
    "30": "30px",
    round: "50%",
  },
  transitions: {
    color: "color .25s",
    bgColor: "background-color .2s",
    border: "border .2s",
    transform: "transform .2s",
  },
  lineHeights: {
    "1": "1",
    "1_16": "1.16",
    "1_3": "1.3",
    "24": "24px",
  },
  sizes: {
    "24": "24px",
    "25": "25px",
    "32": "32px",
    maxGridWidth: "1240px", // + 10px padding
    maxGridHeaderWidth: "1440px",
  },
};

export const media = {
  onlyMobile: "(min-width: 0px) and (max-width:767.5px)", // to jest max z 20px padding
  fromTablet: "(min-width: 768px)",
  onlyTablet: "(min-width: 768px) and (max-width:1023.5px)",
  fromDesktop: "(min-width: 1024px)", // to jest max z 10px padding
  reducedMotion: "(prefers-reduced-motion)",
};

export const config = createStitches({
  theme: themeDefinition,
  prefix: "nju",
  media,
  utils: {
    p: (value: PropertyValue<"padding">) => {
      return {
        paddingTop: value,
        paddingBottom: value,
        paddingLeft: value,
        paddingRight: value,
      };
    },

    pt: (value: PropertyValue<"padding">) => {
      return {
        paddingTop: value,
      };
    },

    pr: (value: PropertyValue<"padding">) => {
      return {
        paddingRight: value,
      };
    },

    pb: (value: PropertyValue<"padding">) => {
      return {
        paddingBottom: value,
      };
    },

    pl: (value: PropertyValue<"padding">) => {
      return {
        paddingLeft: value,
      };
    },

    px: (value: PropertyValue<"padding">) => {
      return {
        paddingLeft: value,
        paddingRight: value,
      };
    },

    py: (value: PropertyValue<"padding">) => {
      return {
        paddingTop: value,
        paddingBottom: value,
      };
    },

    m: (value: PropertyValue<"margin">) => {
      return {
        marginTop: value,
        marginBottom: value,
        marginLeft: value,
        marginRight: value,
      };
    },
    mt: (value: PropertyValue<"margin">) => {
      return {
        marginTop: value,
      };
    },
    mr: (value: PropertyValue<"margin">) => {
      return {
        marginRight: value,
      };
    },
    mb: (value: PropertyValue<"margin">) => {
      return {
        marginBottom: value,
      };
    },
    ml: (value: PropertyValue<"margin">) => {
      return {
        marginLeft: value,
      };
    },
    mx: (value: PropertyValue<"margin">) => {
      return {
        marginLeft: value,
        marginRight: value,
      };
    },
    my: (value: PropertyValue<"margin">) => {
      return {
        marginTop: value,
        marginBottom: value,
      };
    },
    w: (value: PropertyValue<"width">) => {
      return {
        width: value,
      };
    },
    h: (value: PropertyValue<"height">) => {
      return {
        height: value,
      };
    },
  },
});
type Config = typeof config;

function getFromConfig<key extends keyof Config>(
  what: key extends keyof typeof config ? key : never
): Config[key] {
  return config[what];
}

export const css = getFromConfig("css");
export const styled = getFromConfig("styled");
export const globalCss = getFromConfig("globalCss");
export const getCssText = getFromConfig("getCssText");
export const keyframes = getFromConfig("keyframes");

export { default as cx } from "clsx";
