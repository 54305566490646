import * as React from "react";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { BlockContent, Text, BlockText } from "@nju/ui";
import { Icon } from "@nju/icons";
import { Documents } from "./Documents";
import Link from "next/link";
import { ContactUs } from "@nju/feature-portal-banners";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion";

export function Footer() {
  const scrollUp = React.useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const year = React.useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <footer data-cy="footer">
      <BlockContent grid="extraWide" tone="primary">
        <ContactUs />
        <div className="py-6">
          <Accordion collapsible={true}>
            <AccordionItem>
              <AccordionButton
                className="bg-transparent border-none flex items-center cursor-pointer [&[aria-expanded='true']_svg]:rotate-0 w-full"
                data-cy="footer-accordion-button"
              >
                <div className="flex items-center justify-between w-full">
                  <Text size="m" weight="bold">
                    nju to nowoczesna oferta bez zobowiązań
                  </Text>
                  <Icon
                    className="mr-3 shrink-0 w-4 h-4 rotate-180"
                    name="chevron-up"
                    tone="neutral"
                  />
                </div>
              </AccordionButton>
              <AccordionPanel className="mt-3">
                <BlockText size="xs">
                  Już ponad milion klientów zaufało sieci nju mobile, która od
                  samego początku stawia na prostą i przejrzystą ofertę bez
                  zobowiązań, a wszelkie formalności ograniczone są tu do
                  minimum. Usługi nju kupisz także w aplikacji, co oznacza, że
                  płacisz za każdy miesiąc z góry. Dostępne są nju światłowód i
                  nju subskrypcja. Teraz obie te usługi (nju światłowód i nju
                  subskrypcja) możesz połączyć w pakiet, dzięki któremu obniżysz
                  cenę. Aby zaoszczędzić z nju możesz kupić więcej niż jeden
                  numer, by płacić 19 zł/mies. za każdy nju subskrypcja, a także
                  stworzyć pakiet z nju światłowód i płacić o 3 zł/mies. mniej
                  za pierwszy nju subskrypcja (każdy kolejny numer za 19
                  zł/mies.). Nju korzysta z infrastruktury Orange, dzięki czemu
                  może zagwarantować wysoką jakość usług. Klienci nju mobile są
                  zadowoleni z oferty, 96% z nich przyznaje, że poleca nju
                  bliskim i znajomym.
                </BlockText>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <div className="bg-geyser h-microgap w-full my-3 from-tablet:mt-6 from-tablet:bp-4 from-desktop:my-6" />
          <div className="flex flex-col from-tablet:flex-row from-tablet:justify-between gap-y-8">
            <div className="flex flex-col">
              <Documents />
            </div>
            <div className="flex flex-col items-center gap-y-8">
              <div className="flex items-center gap-4 max-w-fit">
                <Link
                  aria-label="Facebook"
                  className="w-12 h-12"
                  data-cy="footer-facebook-button"
                  href="https://www.facebook.com/njumobile"
                  rel="noreferrer"
                >
                  <Icon className="w-full h-full" name="facebook-incircle" />
                </Link>
                <Link
                  aria-label="Instagram"
                  className="w-12 h-12"
                  data-cy="footer-instagram-button"
                  href="https://www.instagram.com/njumobile"
                  rel="noreferrer"
                >
                  <Icon className="w-full h-full" name="instagram-incircle" />
                </Link>
                <Link
                  aria-label="Youtube"
                  className="w-12 h-12"
                  data-cy="footer-youtube-button"
                  href="https://www.youtube.com/njumobile"
                  rel="noreferrer"
                >
                  <Icon className="w-full h-full" name="youtube-incircle" />
                </Link>
                <Icon
                  className="w-12 h-12"
                  data-cy="footer-award-icon"
                  name="award"
                />
              </div>
              <div className="flex items-center text-size-24 from-tablet:ml-auto max-w-fit gap-3">
                <Text size="s">&copy; {year} nju.</Text>
                <Link
                  className="w-12 h-12"
                  data-cy="footer-go-up-button"
                  href="#"
                  onClick={scrollUp}
                >
                  <Icon className="w-full h-full" name="arrow-inside" />
                  <VisuallyHidden>Wróć do góry strony</VisuallyHidden>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <img alt="pipik" src="/images/pipik_footer.svg" />
        </div>
      </BlockContent>
    </footer>
  );
}
