import { Text, A, Badge } from "@nju/ui";
import Link from "next/link";
import { IHeaderMenuItemLinkProps } from "../types";
import { Icon } from "@nju/icons";

export function HeaderMenuMobileContentItemLink({
  href,
  title,
  badge,
  icon,
  additionalIcon,
}: IHeaderMenuItemLinkProps) {
  return (
    <div className="min-h-[27px] flex flex-row gap-1 pl-[39px]">
      {(icon || additionalIcon) && (
        <div className="mr-2 flex items-center">
          {icon && <Icon height={24} name={icon} tone="accent" width={24} />}
          {additionalIcon && (
            <div className="flex gap-[2px] items-center">
              <Text size="l" tone="accent">
                +
              </Text>
              <Icon
                height={24}
                name={additionalIcon}
                tone="accent"
                width={24}
              />
            </div>
          )}
        </div>
      )}
      <Link href={href} passHref={true}>
        <A size="inline" variant="neutral">
          <Text size="l" tone="neutral">
            {title}
          </Text>
        </A>
      </Link>
      {badge && (
        <Badge
          backgroundCerulean={badge.tone === "accent"}
          badgeText={badge?.title}
          small={true}
        />
      )}
    </div>
  );
}
