import { css } from "@nju/css";

export const styles = {
  element: css({
    border: "none",
    outline: "none",
    cursor: "pointer",
    fontWeight: "normal",
    fontFamily: "$ttfis",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    variants: {
      hover: {
        true: {},
      },
      variant: {
        primary: {
          color: "$black",
          borderRadius: "$30",
          background: "$ripeLemon",
          transition: "boxShadow, transform",
          transitionDuration: "0.35s",
          transitionTimingFunction: "cubic-bezier(0.72, 0.07, 0.07, 1)",
          borderWidth: "$2",
          borderStyle: "solid",
          borderColor: "$ripeLemon",
        },
        primaryWithBorder: {
          color: "$black",
          borderRadius: "$30",
          background: "$ripeLemon",
          transition: "boxShadow, transform",
          transitionDuration: "0.35s",
          transitionTimingFunction: "cubic-bezier(0.72, 0.07, 0.07, 1)",
          borderWidth: "$2",
          borderStyle: "solid",
          borderColor: "black",
        },
        secondary: {
          color: "$black",
          background: "$white",
          borderWidth: "$2",
          borderStyle: "solid",
          borderColor: "$black",
          borderRadius: "$30",
          transition: "transform",
          transitionDuration: "0.35s",
          transitionTimingFunction: "cubic-bezier(0.72, 0.07, 0.07, 1)",
        },
        simple: {
          borderRadius: "0",
          background: "none",
          transition: "$color",
          border: "0",
          "&:hover": {
            transform: "none",
            boxShadow: "none",
          },
        },
        google: {
          borderWidth: "$1",
          borderStyle: "solid",
          borderColor: "$geyser",
          borderRadius: "$30",
          fontWeight: "normal",
          backgroundColor: "$white",
        },
        facebook: {
          borderWidth: "$1",
          borderStyle: "solid",
          borderColor: "$geyser",
          borderRadius: "$30",
          fontWeight: "normal",
          backgroundColor: "hsl(221, 44.1%, 41.4%)",
          color: "$white",
        },
        accent: {
          color: "$white",
          borderRadius: "$30",
          background: "$cerulean",
          transition: "boxShadow, transform",
          transitionDuration: "0.35s",
          transitionTimingFunction: "cubic-bezier(0.72, 0.07, 0.07, 1)",
          borderWidth: "$2",
          borderStyle: "solid",
          borderColor: "$cerulean",
        },
        neutral: {
          padding: "0",
          fontSize: "inherit",
          fontWeight: "inherit",
          "&:hover": {
            transform: "none",
            boxShadow: "none",
          },
        },
      },
      isLoading: {
        true: {
          color: "transparent",
          pointerEvents: "none",
        },
      },
      align: {
        left: {
          textAlign: "left",
        },
      },
      size: {
        inline: {
          px: "$0",
          py: "$0",
          fontSize: "$16",
        },
        s: {
          px: "$30",
          py: "$10",
          fontSize: "$16",
        },
        m: {
          px: "$40",
          py: "$15",
          fontSize: "$20",
        },
        l: {
          px: "$40",
          py: "$15",
          fontSize: "$24",
        },
        login: {
          px: "$40",
          py: "13px",
          fontSize: "$20",
          width: "100%",
        },
        register: {
          py: "11px",
          fontSize: "$20",
          width: "100%",
        },
      },
      withUnderline: {
        true: {
          textDecoration: "underline",
        },
      },
      display: {
        flex: {
          display: "flex",
          width: "auto",
        },
        block: {
          display: "block",
          width: "100%",
        },
        inline: {
          display: "inline",
        },
        none: {
          display: "none",
        },
        inlineBlock: {
          display: "inline-block",
        },
        inlineFlex: {
          display: "inline-flex",
        },
      },
    },
    compoundVariants: [
      {
        hover: "true",
        variant: "primary",
        css: {
          transform: "scale(1.1)",
          boxShadow: "0 2px 32px rgba(0,0,0,0.12)",
        },
      },
      {
        hover: "true",
        variant: "secondary",
        css: {
          transform: "scale(1.1)",
          boxShadow: "0 2px 32px rgba(0,0,0,0.12)",
        },
      },
      {
        hover: "true",
        variant: "simple",
        css: {
          color: "$cerulean",
        },
      },
      {
        hover: "true",
        variant: "accent",
        css: {
          transform: "scale(1.1)",
          boxShadow: "0 2px 32px rgba(0,0,0,0.12)",
        },
      },
    ],
    defaultVariants: {
      variant: "primary",
      size: "m",
      display: "flex",
    },
    "&:disabled": {
      opacity: 0.5,
    },
  }),
  spinner: css({
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  }),
  hidden: css({
    variants: {
      isLoading: {
        true: {
          visibility: "hidden",
        },
      },
    },
  }),
};
