import { Icon } from "@nju/icons";
import { Text } from "@nju/ui";
import Link from "next/link";

export function ContactUs() {
  return (
    <div className="bg-porcelain">
      <Icon
        className="w-20 h-[32px] mt-6 from-tablet:mt-12"
        data-cy="nju-footer-logo"
        name="nju"
      />
      <div className="flex only-mobile:flex-col mt-6 gap-y-12 from-desktop:gap-x-8">
        <div className="flex-1">
          <Text className="mb-3" display="block" size="l" weight="bold">
            masz pytania do oferty?
          </Text>
          <Link
            className="from-tablet:pointer-events-none bg-ripeLemon py-3 pl-4 pr-6 rounded-badge inline-block only-mobile:block text-center w-fit"
            data-cy="offer-hotline-button"
            href="tel:+48690300400"
            rel="noreferrer"
            target="_blank"
          >
            <div className="flex items-center gap-4 justify-center">
              <div className="w-6 h-6 bg-black rounded-full flex justify-center items-center">
                <Icon height={30} name="call" width={30} />
              </div>
              <Text size="xl">690 300 400</Text>
            </div>
          </Link>
          <Text className="mt-3" display="block" size="m">
            nasza infolinia jest czynna:
          </Text>
          <Text display="block" size="m">
            pon.-pt. 8:00-22:00, sob.-niedz. 10:00-21:00
          </Text>
          <Text className="mt-3" display="block" size="m">
            opłata zgodna z cennikiem operatora
          </Text>
        </div>
        <div className="flex-1">
          <Text className="mb-3" display="block" size="l" weight="bold">
            masz pytania do usług i płatności?
          </Text>
          <Link
            className="from-tablet:pointer-events-none py-3 pl-4 pr-6 rounded-badge inline-block only-mobile:block text-center border-2 border-daintree w-fit"
            data-cy="service-payment-hotline-button"
            href="tel:+48690610610"
            rel="noreferrer"
            target="_blank"
          >
            <div className="flex items-center gap-4 justify-center">
              <div className="w-6 h-6 bg-black rounded-full flex justify-center items-center">
                <Icon height={30} name="call" width={30} />
              </div>
              <Text size="xl">690 610 610 lub *610</Text>
            </div>
          </Link>
          <Text className="mt-3" display="block" size="m">
            Biuro Obsługi Klienta jest czynne:
          </Text>
          <Text display="block" size="m">
            pon.-sob. 6:00-22:00
          </Text>
          <Text className="mt-3" display="block" size="m">
            bezpieczne połączenie
          </Text>
        </div>
      </div>
    </div>
  );
}
