/* eslint-disable unicorn/no-null */
import { portalRoutes } from "@nju/routes";
import {
  HoverMenuElement,
  IHeaderMenuProps,
  INavigationMenuProps,
} from "./types";

export const headerMenu: IHeaderMenuProps = {
  [HoverMenuElement.account]: {
    links: [
      {
        alert:
          "Jesteś na koncie, w którym możesz zarządzać usługami <b>subskrypcji</b> lub <b>światłowodu</b>.",
        loggedIn: true,
      },
      {
        title: "Masz <b>abonament, na kartę</b> lub <b>internet mobilny</b> ? ",
        link: {
          href: "https://www.njumobile.pl/logowanie",
          title: "przejdź do konta",
        },
        loggedIn: true,
      },
      {
        title: "Masz <b>abonament, na kartę</b> lub <b>internet mobilny</b> ? ",
        link: {
          href: "https://www.njumobile.pl/logowanie",
          title: "przejdź do konta",
        },
        loggedIn: false,
      },
      {
        title: "Masz <b>subskrypcję lub światłowód</b> ? ",
        link: {
          href: portalRoutes.zaloguj.index.get(),
          title: "przejdź do konta",
        },
        loggedIn: false,
      },
    ],
    items: [],
  },
  [HoverMenuElement.offer]: {
    links: [],
    items: [
      {
        icon: "mobile-alt",
        title: "rozmowy",
        links: [
          {
            href: "https://www.njumobile.pl/oferta/abonament-multi",
            title: "abonament",
            badge: {
              title: "bestseller",
              tone: "accent",
            },
          },
          {
            href: "https://www.njumobile.pl/oferta/subskrypcja",
            title: "subskrypcja",
            badge: {
              title: "nowość",
              tone: "info",
            },
          },
          {
            href: "https://www.njumobile.pl/oferta/nju-na-karte",
            title: "na kartę",
          },
          {
            href: "https://www.njumobile.pl/oferta/dokup-numer-dodatkowy",
            title: "numer dodatkowy",
          },
        ],
      },
      {
        icon: "globe",
        title: "internet",
        links: [
          {
            href: "https://www.njumobile.pl/oferta/swiatlowod",
            title: "światłowód",
          },
          {
            href: "https://www.njumobile.pl/oferta/internet",
            title: "mobilny",
          },
        ],
      },
      {
        icon: "percentage",
        title: "promocje",
        links: [
          {
            icon: "mobile-alt",
            additionalIcon: "globe",
            href: "https://www.njumobile.pl/pakiety",
            title: "pakiety",
          },
          {
            icon: "mobile-alt",
            href: "https://www.njumobile.pl/oferta/przenies-numer-do-nju",
            title: "3 mies. za 0 zł",
          },
        ],
      },
      {
        icon: "sparkles",
        title: "dodatkowe",
        links: [
          {
            href: "https://www.njumobile.pl/oferta/uslugi",
            title: "usługi dodatkowe",
          },
          {
            href: "https://www.njumobile.pl/roaming",
            title: "roaming",
          },
          {
            href: "https://doladowania.njumobile.pl/",
            title: "doładowanie konta",
          },
          {
            href: "https://www.njumobile.pl/5g-w-nju",
            title: "sieć 5G",
          },
        ],
      },
    ],
  },
  [HoverMenuElement.help]: {
    links: [],
    items: [
      {
        icon: "list-items",
        title: "więcej o ofercie",
        links: [
          {
            href: "https://www.njumobile.pl/obsluga/uslugi",
            title: "abonament",
          },
          {
            href: portalRoutes.pomoc.get(),
            title: "subskrypcja",
          },
          {
            href: "https://www.njumobile.pl/obsluga/uslugi",
            title: "na kartę",
          },
          {
            href: "https://www.njumobile.pl/obsluga/uslugi",
            title: "numer dodatkowy",
          },
          {
            href: portalRoutes.pomoc.get(),
            title: "światłowód",
          },
          {
            href: "https://www.njumobile.pl/obsluga/uslugi",
            title: "internet mobilny",
          },
        ],
      },
      {
        icon: "database",
        title: "baza wiedzy",
        links: [
          {
            href: "https://www.njumobile.pl/obsluga/faktury-i-platnosci",
            title: "faktury i płatności",
          },
          {
            href: "https://www.njumobile.pl/obsluga/formalnosci",
            title: "formalności",
          },
          {
            href: "https://www.njumobile.pl/obsluga/forum",
            title: "forum",
          },
        ],
      },
    ],
  },
  [HoverMenuElement.null]: {
    links: [],
    items: [],
  },
};

export const navigationMenu: INavigationMenuProps[] = [
  {
    title: "oferta",
    menuElement: HoverMenuElement.offer,
  },
  {
    href: "https://www.njumobile.pl/punkty-sprzedazy",
    title: "sprzedaż",
    menuElement: null,
  },
  {
    title: "pomoc",
    menuElement: HoverMenuElement.help,
  },
  {
    href: "https://www.njumobile.pl/obsluga/kontakt",
    title: "kontakt",
    menuElement: null,
  },
];
